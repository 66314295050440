import React from 'react';

const HomeBuildingIcon32 = ({ className }) => (
  <svg className={className} height="32" width="32">
    <g fill="#8c734b" transform="translate(0 3)">
      <path d="m10 3.94736842-10 6.57894738v14.4736842h20v-14.4736842zm0 3.28947369 7.3333333 4.60526319v10.5263158h-4v-7.8947369h-6.66666663v7.8947369h-4v-10.5263158z" />
      <path
        d="m32 0v25h-9.3333333v-2.6315789h6.6666666v-19.73684215h-13.3333333v2.09210526l-2.6666667-1.75v-2.97368421zm-5.3333333 15.7894737v2.6315789h-2.6666667v-2.6315789zm0-5.2631579v2.6315789h-2.6666667v-2.6315789zm-5.3333334-5.26315791v2.63157895h-.52l-2.1466666-1.40789473v-1.22368422zm5.3333334 0v2.63157895h-2.6666667v-2.63157895z"
        opacity=".5"
      />
    </g>
  </svg>
);

export default HomeBuildingIcon32;
