import React from 'react';

import './ServicesBlock.css';

const ServicesItem = ({ ...props }) => (
  <div className="c-services-item">
    <div className="flex items-center md:flex-col md:items-start">
      <div className="c-services-item__icon">{props.icon}</div>
      <h3 className="text-lg font-bold">{props.title}</h3>
    </div>
    <p className="mt-5 text-brand-medium md:mt-2">{props.description}</p>
  </div>
);

export default ServicesItem;
