import React from 'react';

const RentCollectionIcon32 = ({ className }) => (
  <svg className={className} height="32" width="32">
    <g fill="#8c734b" transform="translate(0 4)">
      <path d="m9.33333333 4.05882353c1.46666667 0 2.66666667 1.21764706 2.66666667 2.70588235 0 1.4882353-1.2 2.70588236-2.66666667 2.70588236-1.46666666 0-2.66666666-1.21764706-2.66666666-2.70588236 0-1.48823529 1.2-2.70588235 2.66666666-2.70588235m0-2.70588235c-2.94666666 0-5.33333333 2.4217647-5.33333333 5.4117647s2.38666667 5.41176472 5.33333333 5.41176472c2.94666667 0 5.33333337-2.42176472 5.33333337-5.41176472s-2.3866667-5.4117647-5.33333337-5.4117647m0 13.52941172c-5.16 0-9.33333333 2.4217647-9.33333333 5.4117647v2.7058824h14.6666667v-2.7058824h-12.00000003c0-.7847058 2.33333333-2.7058823 6.66666666-2.7058823 2.43999997 0 4.22666667.6088235 5.33333337 1.2852941v-3.0170588c-1.5066667-.6088235-3.3333334-.9741177-5.33333337-.9741177z" />
      <path
        d="m32 0v23h-14.6666667v-23zm-5.3333333 2.70588235h-4c0 1.44400453-1.0971401 2.61281762-2.4972501 2.70059021l-.1694166.00529215v12.17647059c1.4230769 0 2.5749507 1.1257831 2.6614512 2.5354176l.0052155.1704647h4c0-1.4309954 1.1094674-2.6118169 2.4986724-2.7005324l.1679942-.0053499v-12.17647059c-1.4666666 0-2.6666666-1.20411765-2.6666666-2.70588236zm-2 5.41176471c1.84 0 3.3333333 1.51529412 3.3333333 3.38235294 0 1.8670588-1.4933333 3.3823529-3.3333333 3.3823529s-3.3333334-1.5152941-3.3333334-3.3823529c0-1.86705882 1.4933334-3.38235294 3.3333334-3.38235294z"
        opacity=".5"
      />
    </g>
  </svg>
);

export default RentCollectionIcon32;
