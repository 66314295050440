import React from 'react';

const HRIcon32 = ({ className }) => (
  <svg className={className} height="32" width="32">
    <g fill="#8c734b" transform="translate(0 4)">
      <path d="m5.60028001 5.6016c0-3.104 2.49612481-5.6 5.60027999-5.6 3.0881544 0 5.60028 2.496 5.60028 5.6 0 3.088-2.5121256 5.6-5.60028 5.6-3.10415518 0-5.60027999-2.512-5.60027999-5.6m3.20016001-.0016c0 1.312 1.0720536 2.4 2.40011998 2.4 1.3120656 0 2.40012-1.088 2.40012-2.4 0-1.328-1.0880544-2.4-2.40012-2.4-1.32806638 0-2.40011998 1.072-2.40011998 2.4m13.60068008 15.2v3.2h-22.4011201v-3.2s0-6.4 11.20056-6.4c11.2005601 0 11.2005601 6.4 11.2005601 6.4m-3.2001601 0c-.2240112-1.248-2.1281064-3.2-8.0004-3.2-5.87229359 0-7.88839439 2.096-8.00039999 3.2" />
      <path
        d="m22.3208856 14.4.3603714.0260524c9.318743.7177842 9.318743 6.3739476 9.318743 6.3739476v3.2h-6.40032v-3.2c-.0960048-2.528-1.2960648-4.864-3.280164-6.4zm-1.5198455-14.4c3.0881544 0 5.60028 2.496 5.60028 5.6 0 3.01447619-2.3939247 5.4800544-5.3807034 5.595754l-.2195766.004246c-1.0120507 0-2.0106561-.2823333-2.8602449-.7977037l-.2199096-.1422963.1619186-.2358195c1.8124643-2.76905801 1.7558248-6.36046285-.1699186-9.0625805v-.016c.8960448-.624 1.9840992-.96 3.0881545-.944z"
        opacity=".5"
      />
    </g>
  </svg>
);

export default HRIcon32;
