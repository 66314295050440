import React from 'react';

const ReportsIcon32 = ({ className }) => (
  <svg className={className} height="32" width="32">
    <g fill="#8c734b" transform="translate(3)">
      <path d="m18.9 0h-10.8c-1.485 0-2.7 1.2-2.7 2.66666667v21.33333333c0 1.4666667 1.215 2.6666667 2.7 2.6666667h16.2c1.485 0 2.7-1.2 2.7-2.6666667v-16zm5.4 24h-16.2v-21.33333333h9.45v6.66666666h6.75zm-13.5-10.6666667v2.6666667h10.8v-2.6666667zm0 5.3333334v2.6666666h6.75v-2.6666666z" />
      <path
        d="m2.7 5.33333333v23.99999997h21.6v2.6666667h-21.6c-1.485 0-2.7-1.2-2.7-2.6666667v-23.99999997z"
        opacity=".5"
      />
    </g>
  </svg>
);

export default ReportsIcon32;
