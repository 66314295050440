import React from 'react';

import './ServicesBlock.css';
import links from '../../data/links.json';
import ServicesItem from './ServicesItem';
import FinanceIcon from '../Common/Icons/32/FinanceIcon32';
import HRIcon from '../Common/Icons/32/HRIcon32';
import RentCollectionIcon from '../Common/Icons/32/RentCollectionIcon32';
import HomeBuildingIcon from '../Common/Icons/32/HomeBuildingIcon32';
import PropertyMaintenanceIcon from '../Common/Icons/32/PropertyMaintenanceIcon32';
import ReportsIcon from '../Common/Icons/32/ReportsIcon32';
import Button from '../Common/Button/Button';

const ServicesBlock = () => (
  <section className="c-services-block">
    <div className="l-container l-container--md flex flex-col">
      <h2 className="c-services-block__title">
        Contracted services that Luxor Management provides
      </h2>
      <div className="c-services-block__grid">
        <ServicesItem
          icon={<FinanceIcon />}
          title="Operating Budget"
          description="Preparation of annual operating and/or capital budgets for approval by the principals."
        />
        <ServicesItem
          icon={<HRIcon />}
          title="Human Resources"
          description="Management and remuneration for all employees required for property operation."
        />
        <ServicesItem
          icon={<RentCollectionIcon />}
          title="Rent Collection"
          description="Collection and deposits of all rents into custodial accounts."
        />
        <ServicesItem
          icon={<HomeBuildingIcon />}
          title="Leasing & Rental Programs"
          description="Implementation and maintenance of active leasing/rental programs."
        />
        <ServicesItem
          icon={<PropertyMaintenanceIcon />}
          title="Property Maintenance"
          description="Property operation and maintenance to standards prescribed by the principals. Development, negotiation and management of programs relating to maintenance, repairs and capital replacements."
        />
        <ServicesItem
          icon={<ReportsIcon />}
          title="Financial Reports"
          description="Preparation and provision of complete monthly financial reports (accrual or cash basis), with an editorial summary of monthly issues."
        />
      </div>
      <Button
        className="self-center"
        link={links.contact}
        label="Inquire About Our Services"
      />
    </div>
  </section>
);

export default ServicesBlock;
