import React from 'react';

const FinanceIcon32 = ({ className }) => (
  <svg className={className} height="32" width="32">
    <g fill="#8c734b">
      <path d="m26.6273684 16.5221053-3.048421-3.0484211h8.4210526v8.4210526l-3.0147368-3.0147368-12.1431579 12.0421053-5.8442106-5.0863158-6.36631575 6.1642105h-4.63157895l10.8968421-10.6778947 5.9452632 5.0189473" />
      <path
        d="m5.05263158 13.4736842v9.2631579l-5.05263158 4.951579v-14.2147369zm16.84210522-13.4736842v16.8421053l-5.0526315 5.0526315v-21.8947368zm-8.4210526 6.73684211v12.90105259l-2.6442105-2.2568421-2.40842107 2.2231579v-12.86736839z"
        opacity=".5"
      />
    </g>
  </svg>
);

export default FinanceIcon32;
