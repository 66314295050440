import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Hero.css';

const Hero = ({ ...props }) => (
  <section
    className={classNames('c-hero', {
      'c-hero--extend': props.extend,
    })}
  >
    <div className="l-container text-center relative z-10">
      <div className="max-w-2xl mx-auto">{props.children}</div>
    </div>
    <div className="c-hero__background">{props.background}</div>
  </section>
);

Hero.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Hero;
