import React from 'react';

const PropertyMaintenanceIcon32 = ({ className }) => (
  <svg className={className} height="32" width="32">
    <g fill="#8c734b" transform="translate(0 1)">
      <path
        d="m18.8796764 20.3212797 9.7067746 9.6787203 3.413549-3.4843393-9.7067745-9.6787203z"
        opacity=".5"
      />
      <path d="m24.8978766 11.9330555c-.6309403 0-1.3104145-.080656-1.8442871-.3064928l-18.42669365 18.2927813-3.41354904-3.4036833 11.98786649-11.9370883-2.8634984-2.8713537-1.16481298 1.129184-2.34580384-2.2744992v4.6135233l-1.13245703 1.129184-5.69464105-5.7427073 1.13245703-1.12918405h4.54600606l-2.26491405-2.27449926 5.75935288-5.74270735c1.89282108-1.88735045 4.93427708-1.88735045 6.82709808 0l-3.413549 3.48433929 2.281092 2.25836806-1.148635 1.14531523 2.8958544 2.87135368 2.9443883-3.03266568c-.2264914-.53232962-.3235592-1.20984004-.3235592-1.80669445 0-3.17784649 2.5237614-5.67818255 5.6622851-5.67818255.9544995 0 1.7957533.2258368 2.5561173.67751042l-4.3195146 4.30703051 2.4266936 2.41968006 4.3195147-4.30703051c.4529828.75816642.6794742 1.56472644.6794742 2.58099207 0 3.09719048-2.5075834 5.59752653-5.6622852 5.59752653z" />
    </g>
  </svg>
);

export default PropertyMaintenanceIcon32;
